import { defineStore } from 'pinia'
import store from '@/store/pinia'
import { Base64 } from 'js-base64'

import {
  setToken,
  setUserInfo,
  setRoles,
  getRoles,
  setBusinessInfo,
  removeBusinessInfo,
  removeToken,
  removeUserInfo,
  removeRoles,
  setResources,
  removeResources,
  getUserInfo,
  getResources,
} from '@/utils/auth'

const useAuthStore = defineStore('auth', {
  state: () => {
    const userInfo = getUserInfo()
    const role = getRoles()
    const roleName = role?.[0]?.roleName

    const bussinessIds = (userInfo?.userId?.split(',') || []).filter(item => !!item)
    const channelIds = (userInfo?.channel?.split(',') || []).filter(item => !!item)

    return {
      userInfo,
      roleName,
      resouces: getResources(),
      subChanneId: userInfo?.channel,
      bussinessId: userInfo?.userId,
      showBusiness: (bussinessIds.length == 0 && channelIds.length == 0) || bussinessIds.length > 1,
      showChannel:
        (bussinessIds.length > 0 && channelIds.length != 1) ||
        (bussinessIds.length == 0 && channelIds.length == 0) ||
        (bussinessIds.length == 0 && channelIds.length > 1),
      isSubChanneManager: !!userInfo?.channel, // 是否为子渠道管理员
      isPlatformManager: userInfo ? !userInfo.userId && !userInfo?.channel && !userInfo.appId : false,
    }
  },
  actions: {
    // 登录
    async login(data) {
      const { businessList, channelList, tokenDto, isDeliver, postCodes } = data
      const userInfo = tokenDto
      const {
        accesstoken,
        subjectModel: {
          authority: { role, resource },
          userInfoDto,
        },
        timeOut,
      } = userInfo

      if (businessList) {
        const businessChannel: string[] = []
        businessList.forEach(item => {
          const { name, id } = item
          const subChannels: string[] = []
          channelList.forEach((channel: any) => {
            if (channel.businessId == id) {
              subChannels.push(name + '-' + channel?.name)
            }
          })
          if (subChannels && subChannels.length) {
            businessChannel.push(...subChannels)
          } else {
            businessChannel.push(name)
          }
        })
        userInfoDto.businessChannel = businessChannel.join(',\n')
      } else {
        userInfoDto.businessChannel = '全平台（平台管理员）'
      }
      if (userInfoDto.channel == 0 || userInfoDto.channel == '0') {
        userInfoDto.channel = ''
      }
      userInfoDto.isDeliver = isDeliver
      userInfoDto.postCodes = postCodes
      const roleName = role?.[0]?.roleName
      this.roleName = roleName
      // 登录请求成功后接收返回的用户信息和资源信息
      this.userInfo = userInfoDto
      this.resouces = resource
      this.isPlatformManager = userInfoDto ? !(userInfoDto.userId && userInfoDto.appId) : false
      const businessInfo = `businessId=${userInfoDto.userId}&businessCode=${userInfoDto.appId}`
      setBusinessInfo(Base64.encode(businessInfo))
      setToken(accesstoken, timeOut)
      setUserInfo(userInfoDto)
      setRoles(role)
      setResources(resource)
    },
    /**
     * 退出登录
     * @param stopThread 是否停止线程
     */
    async logout(stopThread = false) {
      removeRoles()
      removeToken()
      removeUserInfo()
      removeResources()
      removeBusinessInfo()
      // 页面重定向登录页
      const pathname = window.location.pathname
      const isNotLoginPage = pathname !== '/login' && pathname !== '/'
      const href = isNotLoginPage ? '/login?redirect=' + encodeURIComponent(location.href) : '/login'

      location.href = href

      if (stopThread) {
        // 停止js线程
        throw new Error('user logout')
      }
    },
  },
})

export default useAuthStore

// Need to be used outside the setup
export function useAuthStoreWithOut() {
  return useAuthStore(store)
}
