import type { App } from 'vue'
import { DeviceType } from '@/typings/modules/store'
import router from '@/router'
import LayoutStore from '@/layouts'

function getScreenType() {
  const width = document.body.clientWidth
  if (width <= 768) {
    return DeviceType.MOBILE
  } else if (width < 992 && width > 768) {
    return DeviceType.PAD
  } else if (width < 1200 && width >= 992) {
    return DeviceType.PC
  } else {
    return DeviceType.PC
  }
}

const install: (app: App) => void = app => {
  app.use(LayoutStore, {
    state: {
      device: getScreenType(),
    },
    actions: {
      onPersonalCenter() {
        router.push('/personal')
      },
    },
  })
}

export default install
