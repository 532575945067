<template>
  <div class="vaw-avatar-container">
    <a-dropdown v-model:popup-visible="showPopMenu" trigger="click" size="large" @select="handleSelect">
      <div class="action-wrapper flex">
        <div>
          <div class="nick-name">
            {{ userStore.userInfo.account }}
          </div>
          <a-tooltip :content="showTip" position="bottom">
            <div class="business-info">{{ userStore?.userInfo?.businessChannel }}</div>
          </a-tooltip>
        </div>

        <icon-caret-down
          class="tip"
          :class="{
            'tip-rotate': showPopMenu,
          }"
        />
      </div>
      <template #content>
        <a-doption v-for="item of options" :key="item.key" :value="item.key">
          <template #icon>
            <component :is="item.icon" />
          </template>
          {{ item.label }}
        </a-doption>
      </template>
    </a-dropdown>

    <change-password-modal v-model:show="showChangePwd" />
  </div>
</template>

<script lang="ts">
  import { Modal } from '@arco-design/web-vue'
  import { defineComponent } from 'vue'
  import { useLayoutStore } from '../index'
  import {
    IconUser as UserOutlined,
    IconPoweroff as LogoutOutlined,
    IconCaretDown as CaretDownOutlined,
  } from '@arco-design/web-vue/es/icon'
  import useUserStore from '@/store/modules/auth'

  export default defineComponent({
    name: 'UserAvatar',
    components: { UserOutlined, LogoutOutlined, CaretDownOutlined },
    setup() {
      const showChangePwd = ref(false)
      const showPopMenu = ref(false)
      const store = useLayoutStore()
      const userStore = useUserStore()
      const roleName = userStore?.roleName ? `（${userStore?.roleName}）` : ''
      const showTip = userStore?.userInfo?.businessChannel + roleName
      const options = [
        {
          label: '修改密码',
          key: 'personal-center',
          icon: 'icon-pen-fill',
        },
        {
          label: '退出登录',
          key: 'logout',
          icon: 'LogoutOutlined',
        },
      ]
      function changePassword() {
        showChangePwd.value = true
      }
      function logout() {
        Modal.confirm({
          title: '提示',
          content: '是否要退出当前账号？',
          okText: '退出',
          cancelText: '再想想',
          onOk: () => {
            userStore.logout().then(() => {
              ;(store as any).onLogout && (store as any).onLogout()
            })
          },
        })
      }
      function handleSelect(key: string) {
        console.log(key)
        switch (key) {
          case 'personal-center':
            changePassword()
            break
          case 'logout':
            logout()
            break
        }
      }
      return {
        userStore,
        options,
        showTip,
        showChangePwd,
        showPopMenu,
        handleSelect,
      }
    },
  })
</script>

<style lang="less" scoped>
  .vaw-avatar-container {
    cursor: pointer;
    .tip-rotate {
      transform: rotate(180deg);
      transition: transform @transitionTime;
    }
    .action-wrapper {
      display: flex;
      align-items: center;
      .avatar {
        display: flex;
        align-items: center;
        & > img {
          border: 1px solid #f6f6f6;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .nick-name {
        margin: 0;
        .tip {
          transform: rotate(0);
          transition: transform @transitionTime;
          margin-left: 2px;
        }
      }
      .business-info {
        color: #999999;
        font-size: 10px;
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
      }
    }
  }
</style>
