<template>
  <svg aria-hidden="true" :style="getStyle" class="arco-icon">
    <use :xlink:href="symbolId" />
  </svg>
</template>

<script lang="ts" setup name="SvgIcon">
  const props = defineProps({
    prefix: {
      type: String,
      default: 'icon',
    },
    name: {
      type: String,
      required: true,
    },
    size: {
      type: [Number, String],
      default: null,
    },
  })

  const symbolId = computed(() => `#${props.prefix}-${props.name}`)
  const getStyle = computed(() => {
    const style: any = {}
    const { size } = props
    if (size !== null) {
      let s = `${size}`
      s = `${s.replace('px', '')}px`
      style.width = s
      style.height = s
    }

    return style
  })
</script>

<style lang="css" scoped>
  svg {
    fill: currentColor;
  }
</style>
