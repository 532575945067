<template>
  <a-card :bordered="false" :body-style="{ padding: 0 }">
    <div class="vaw-nav-bar-wrapper">
      <humburger />
      <div style="flex: 1" />
      <div class="right-wrapper">
        <right-actions />
      </div>
      <a-divider direction="vertical" />
      <div class="avatar-wrapper">
        <user-avatar />
      </div>
    </div>
  </a-card>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { useLayoutStore } from '../index'
  import RightActions from '../right-actions/index.vue'
  import UserAvatar from '../user-avatar/index.vue'
  import Breadcrumb from '../breadcrumb/index.vue'
  import Humburger from '../humburger/index.vue'

  export default defineComponent({
    name: 'NavBar',
    components: {
      RightActions,
      Breadcrumb,
      Humburger,
      UserAvatar,
    },
    setup() {
      const store = useLayoutStore()
      return {
        state: store?.state,
      }
    },
  })
</script>

<style scoped lang="less">
  .vaw-nav-bar-wrapper {
    height: @logoHeight;
    max-height: @logoHeight;
    min-height: @logoHeight;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--color-border);
    .avatar-wrapper {
      padding-right: 20px;
      margin-left: 10px;
    }
    .right-wrapper {
      height: 100%;
    }
  }
</style>
