/*
 * @Author: zhangzhenfei
 * @Date: 2022-02-11 14:41:36
 * @LastEditTime: 2022-03-10 16:56:04
 * @LastEditors: zhangzhenfei
 * @Description: 路由守卫
 * @FilePath: /sadais-work/src/router/guard/index.ts
 */
import { Router } from 'vue-router'
import NProgress from 'nprogress'
import { createCacheGuard } from './cache'
import { createPermissionGuard } from './permission'

NProgress.configure({ showSpinner: false })

export function setupRouterGuard(router: Router) {
  router.beforeEach(() => {
    NProgress.start()
  })
  router.afterEach(() => {
    NProgress.done()
  })
  createCacheGuard(router)
  createPermissionGuard(router)
}
