/*
 * @Author: zhangzhenfei
 * @Date: 2022-03-10 16:52:51
 * @LastEditTime: 2022-03-10 16:55:40
 * @LastEditors: zhangzhenfei
 * @Description: 路由缓存守卫
 * @FilePath: /sadais-work/src/router/guard/cache.ts
 */
import { Router, RouteLocationNormalized } from 'vue-router'
import layoutStore from '@/store'

export function createCacheGuard(router: Router) {
  function addCachedView(route: RouteLocationNormalized) {
    const routeName = route.name as string
    if (routeName && ['404', '500', '403', 'not-found', 'Login'].includes(routeName)) {
      return
    }
    if (route.meta.noShowTabbar) {
      return
    }
    if (route.query?.noShowTabbar) {
      return
    }
    if (route.meta.hidden) return
    layoutStore.addVisitedView(route)
  }

  router.beforeEach(async (to, from, next) => {
    console.log('路由缓存守卫')
    addCachedView(to)
    next()
  })
}
