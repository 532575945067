/*
 * @Author: zhangzhenfei
 * @Date: 2022-02-11 14:42:04
 * @LastEditTime: 2022-08-02 16:20:52
 * @LastEditors: zhangzhenfei
 * @Description: 权限路由守卫
 * @FilePath: /huafa-external-portals-front/Users/feilin/workspace/sadais/jaundice/jaundice-web-console/src/router/guard/permission.ts
 */
import { Router } from 'vue-router'
import PageLayout from '@/layouts/Layout.vue'
import { useAuthStoreWithOut } from '@/store/modules/auth'
import { getToken } from '@/utils/auth'
import { IResource } from '@/typings/modules/resource'
import { camelCase, upperFirst, cloneDeep } from 'lodash-es'
import { isExternal } from '@/utils'
import ExceptionComponent from '@/views/exception/500.vue'
import { useAppStoreWithOut } from '@/store/modules/app'
import sortBy from 'lodash-es/sortBy'
const appStore = useAppStoreWithOut()
const dynamicViewsModules = import.meta.glob('../../views/**/*.{vue,tsx}')

export function createPermissionGuard(router: Router) {
  let hasAddAsyncRouter = false

  router.beforeEach(async (to, from, next) => {
    console.log('权限路由守卫')
    const authStore = useAuthStoreWithOut()
    if (to.path === '/login') {
      next()
      return
    }
    const token = getToken()
    if (!token) {
      authStore.logout()
    }
    if (!hasAddAsyncRouter) {
      hasAddAsyncRouter = true

      const asyncRouters = generatorRoutes(authStore.resouces)
      console.log('构建动态路由', asyncRouters)
      appStore.initRoutes(asyncRouters)

      router.addRoute({
        name: 'root',
        path: '/',
        component: PageLayout,
        children: flatRoutes(asyncRouters),
      })

      // 动态添加路由后，此处应当重定向到fullPath，否则会加载404页面内容
      next({ path: to.fullPath, replace: true, query: to.query })
    } else {
      // AppModule.addCachedView(to)
      next()
    }
  })
}

function dynamicImport(component: string) {
  if (!component) return ''
  const keys = Object.keys(dynamicViewsModules)
  const matchKeys = keys.filter(key => {
    const k = key.replace('../../views', '')
    const startFlag = component.startsWith('/')
    const endFlag = component.endsWith('.vue') || component.endsWith('.tsx')
    const startIndex = startFlag ? 0 : 1
    const lastIndex = endFlag ? k.length : k.lastIndexOf('.')
    return k.substring(startIndex, lastIndex) === component
  })
  if (matchKeys?.length === 1) {
    const matchKey = matchKeys[0]
    const component = dynamicViewsModules[matchKey]
    return component
  } else if (matchKeys?.length > 1) {
    console.warn('请不要在views文件夹下的同一分层目录中创建具有相同文件名的`.vue/tsx`文件。 这会导致动态引入失败。')
    return ExceptionComponent
  } else {
    console.warn('在src/views/下找不到`' + component + '.vue/tsx`, 请自行创建!')
    return ExceptionComponent
  }
}

/**
 * 根据资源生成路由树
 * @param resources 资源列表
 * @param breadcrumbs 面包屑
 * @returns 路由树
 */
function generatorRoutes(resources: Array<IResource>, breadcrumbs: any[] = []) {
  // 根据sort排序
  resources = sortBy(resources, function (item: IResource) {
    return -item.sort
  })
  const routes: Array<RouteRecordRawWithHidden> = []
  resources.forEach(res => {
    const componentPath = res.componentPath
    const name = upperFirst(camelCase(componentPath || res.fullPath)).trim()
    const meta = res.meta ? JSON.parse(res.meta) : {}
    // 获取挂在此路由下的所有权限
    const auths = res.resourceDtoList.map((subRes: IResource) => subRes.path)
    const hidden = res.type === 0 ? !!meta.hide : true // 0: 菜单，1: 按钮
    // 构建面包屑
    const childrenBreadcrumbs = cloneDeep(breadcrumbs)
    childrenBreadcrumbs.push({
      path: res.fullPath,
      title: res.resName,
    })
    const route: RouteRecordRawWithHidden = {
      fullPath: res.fullPath,
      path: res.fullPath,
      name,
      hidden,
      component: dynamicImport(componentPath),
      meta: {
        title: res.resName,
        cacheable: meta?.cache !== false,
        icon: res.icon || 'icon-menu',
        isExternal: isExternal(res.path),
        breadcrumbs: childrenBreadcrumbs,
        auths,
      },
    }
    if (res.resourceDtoList) {
      route.children = generatorRoutes(res.resourceDtoList, childrenBreadcrumbs)
    }
    routes.push(route)
  })
  return routes
}

/**
 * 扁平化路由，将路由树扁平化为一维数组
 * @param route 路由节点
 * @returns 一维数组路由
 */
export const flatRoutes = (routes: RouteRecordRawWithHidden[]) => {
  return routes.reduce((pre: RouteRecordRawWithHidden[], cur: RouteRecordRawWithHidden) => {
    const route = cloneDeep(cur)
    const children = route.children
    delete route.children
    pre.push(route)
    if (children) {
      pre = pre.concat(flatRoutes(children))
    }
    return pre
  }, [])
}
