<template>
  <div
    class="vaw-side-bar-wrapper"
    :style="{ borderRadius: '0px', marginTop: state.layoutMode === 'ttb' ? '48px' : 0 }"
    :class="[!state.isCollapse ? 'open-status' : 'close-status', bgColor]"
  >
    <transition name="logo">
      <logo v-if="showLogo" />
    </transition>
    <scroller-menu :routes="routes" />
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent } from 'vue'
  import { useLayoutStore } from '../index'
  import { useAppStoreWithOut } from '@/store/modules/app'

  export default defineComponent({
    name: 'SideBar',
    props: {
      showLogo: {
        type: Boolean,
        default: true,
      },
    },
    setup() {
      const store = useLayoutStore()
      const appStore = useAppStoreWithOut()
      const routes = appStore.routes
      const bgColor = computed(() => {
        if (store.state.sideBarBgColor === 'image') {
          return 'sidebar-bg-img'
        } else if (store.state.sideBarBgColor === 'dark') {
          return 'sidebar-bg-dark'
        } else {
          return 'sidebar-bg-light'
        }
      })
      return {
        state: store?.state,
        routes,
        bgColor,
      }
    },
  })
</script>

<style scoped lang="less">
  .sidebar-bg-img {
    background-image: url('../../assets/bg_img.webp') !important;
    background-size: cover;
    :deep(.logo-wrapper .logo-title) {
      color: #fff !important;
    }
    :deep(.arco-menu) {
      background-color: transparent !important;
    }
    :deep(.arco-menu-inline-header) {
      background-color: transparent !important;
    }
    :deep(.arco-menu-dark .arco-menu-pop-header) {
      background-color: transparent !important;
    }
    :deep(.arco-menu-item) {
      background-color: transparent !important;
    }
    :deep(.arco-menu-dark .arco-menu-item.arco-menu-selected) {
      color: rgb(var(--primary-6));
      & .arco-menu-icon {
        color: rgb(var(--primary-6)) !important;
        & .arco-icon {
          color: currentColor !important;
        }
      }
    }
    :deep(.arco-menu-dark .arco-menu-item:hover, .arco-menu-dark .arco-menu-group-title:hover, .arco-menu-dark
        .arco-menu-pop-header:hover, .arco-menu-dark .arco-menu-inline-header:hover) {
      color: rgb(var(--primary-6)) !important;
      & .arco-menu-icon {
        color: rgb(var(--primary-6)) !important;
        & .arco-icon {
          color: currentColor !important;
        }
      }
    }
    :deep(.arco-menu-dark .arco-menu-inline-header:hover) {
      color: rgb(var(--primary-6)) !important;
      & .arco-menu-icon {
        color: rgb(var(--primary-6)) !important;
        & .arco-icon {
          color: currentColor !important;
        }
      }
    }
    :deep(.arco-menu-dark .arco-menu-pop-header:hover) {
      color: rgb(var(--primary-6)) !important;
      & .arco-menu-icon {
        color: rgb(var(--primary-6)) !important;
        & .arco-icon {
          color: currentColor !important;
        }
      }
    }
  }
  .sidebar-bg-dark {
    background-color: var(--color-menu-dark-bg);
    :deep(.logo-wrapper .logo-title) {
      color: #fff !important;
    }
    :deep(.arco-menu-dark .arco-menu-item.arco-menu-selected) {
      color: var(--color-white);
      background-color: rgb(var(--primary-6));
      & .arco-menu-icon {
        color: var(--color-white) !important;
        & .arco-icon {
          color: currentColor !important;
        }
      }
    }
    :deep(.arco-menu-dark .arco-menu-item:hover, .arco-menu-dark .arco-menu-group-title:hover, .arco-menu-dark
        .arco-menu-pop-header:hover, .arco-menu-dark .arco-menu-inline-header:hover) {
      color: var(--color-white) !important;
      & .arco-menu-icon {
        color: var(--color-white) !important;
        & .arco-icon {
          color: currentColor !important;
        }
      }
    }
    :deep(.arco-menu-dark .arco-menu-inline-header:hover) {
      color: rgb(var(--primary-6)) !important;
      & .arco-menu-icon {
        color: rgb(var(--primary-6)) !important;
        & .arco-icon {
          color: currentColor !important;
        }
      }
    }
    :deep(.arco-menu-dark .arco-menu-pop-header:hover) {
      color: rgb(var(--primary-6)) !important;
      & .arco-menu-icon {
        color: rgb(var(--primary-6)) !important;
        & .arco-icon {
          color: currentColor !important;
        }
      }
    }
  }
  .sidebar-bg-light {
    background-color: #fff;
  }
  .open-status {
    border-right: 1px solid var(--color-border-2);
    width: @menuWidth;
    transition: all @transitionTime;
  }
  .close-status {
    width: @minMenuWidth;
    box-shadow: none;
    transition: all @transitionTime;
  }
  .vaw-side-bar-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
    height: 100%;
    box-sizing: border-box;
    z-index: 999;
    .vaw-menu-wrapper {
      overflow-x: hidden;
      color: white;
    }
  }
  .is-mobile {
    .open-status {
      width: @menuWidth;
      transform: translateX(0);
      transition: transform @transitionTime;
    }
    .close-status {
      width: @menuWidth;
      @negativeMenuWidth: calc(@menuWidth * -1);
      transform: translateX(@negativeMenuWidth);
      transition: transform @transitionTime;
      box-shadow: none;
    }
  }
</style>
