<!--
 * @Author: zhangzhenfei
 * @Date: 2022-04-19 11:57:00
 * @LastEditTime: 2022-04-29 14:53:35
 * @LastEditors: zhangzhenfei
 * @Description: 
 * @FilePath: \jaundice-web-console\src\components\business\statistics-draw\index.vue
-->
<template>
  <a-drawer
    :visible="syncedShow"
    width="850px"
    unmountOnClose
    :footer="false"
    :mask-closable="true"
    @cancel="handleClose"
  >
    <template #title> 数据统计 </template>
    <a-spin :loading="loading" style="width: 100%">
      <div class="mt-10">
        <a-space size="medium" align="center">
          时间范围： <a-range-picker v-model="searchParams.date" style="width: 250px" />
          <template>
            渠道选择：
            <channel-selector ref="channelSelector" v-model:value="searchParams.searchValue.businessId" width="250px" />
          </template>
          <a-button type="primary" @click="handleSearch">
            <template #icon>
              <icon-search />
            </template>
            查询
          </a-button></a-space
        >
        <a-divider orientation="left" style="margin-top: 50px">服务统计</a-divider>

        <div class="flex pt-5">
          <div
            class="flex-1 text-center data-item"
            v-for="item in resultList.filter(item => item.type == 0)"
            :key="item.label"
          >
            <a-tooltip :content="item.tip" position="top">
              <div class="text-gray-500">
                {{ item.label }}

                <icon-info-circle class="ml-1" />
              </div>
            </a-tooltip>

            <div class="text-black mt-4" style="font-size: 48px">
              {{ item.value }}
              <span class="text-black-500 font-normal" style="font-size: 24px">{{ item.unit }}</span>
            </div>
          </div>
        </div>

        <a-divider orientation="left" style="margin-top: 50px">设备统计</a-divider>

        <div class="flex pt-5">
          <div
            class="flex-1 text-center data-item"
            v-for="item in resultList.filter(item => item.type == 1)"
            :key="item.label"
          >
            <div class="text-gray-500"> {{ item.label }}</div>
            <div class="text-black mt-4" style="font-size: 48px">
              {{ item.value }}
              <span class="text-black-500 font-normal" style="font-size: 24px">{{ item.unit }}</span>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </a-drawer>
</template>
<script lang="ts" setup name="StatisticsInfo">
  import { apiGetHireStatistics, apiGetDeviceStatistics } from '@/api/modules/common'
  import useUserStore from '@/store/modules/auth'
  const userStore = useUserStore()

  const { proxy, ctx } = getCurrentInstance() as any

  const emit = defineEmits(['update:show', 'confirm'])

  const props = defineProps({
    show: {
      type: Boolean,
      default: true,
    },
  })
  const syncedShow = useVModel(props, 'show', emit, { passive: true })

  const searchParams = reactive({
    date: [],
    startDate: '',
    endDate: '',
    searchValue: {
      businessId: '',
    },
  })

  type IStatisticsData = {
    avgSettleDayCount?: number //平均结算天数
    orderCount?: number //租用台数-下单数量
    settleCount?: number //当月结算台数
    settleDayCount?: number //当月结算总天数
    deviceCount?: number // 设备数量
    distributeCount?: number // 未分发设备
    leaseCount?: number // 已租
    noLeaseCount?: number // 未租
    sellCount?: number // 已售  押金扣完变为已售
  }

  const result = reactive<IStatisticsData>({
    avgSettleDayCount: 0,
    orderCount: 0,
    settleCount: 0,
    settleDayCount: 0,
    deviceCount: 0,
    distributeCount: 0,
    leaseCount: 0,
    noLeaseCount: 0,
    sellCount: 0,
  })
  const resultList = computed(() => {
    const list = [
      { label: '租用台数', value: result.orderCount, unit: '台', type: 0, tip: '用户下单服务的设备数量' },
      { label: '结算台数', value: result.settleCount, unit: '台', type: 0, tip: '已完成服务的设备' },
      {
        label: '结算总天数',
        value: result.settleDayCount,
        unit: '天',
        type: 0,
        tip: '已结算服务的总天数（包含赠送天数）',
      },
      { label: '平均结算天数', value: result.avgSettleDayCount, unit: '天', type: 0, tip: '结算总天数/结算台数' },

      { label: '设备总数', value: result.deviceCount, unit: '台', type: 1 },
      { label: '已租', value: result.leaseCount, unit: '台', type: 1 },
      { label: '未租', value: result.noLeaseCount, unit: '台', type: 1 },
      { label: '已售', value: result.sellCount, unit: '台', type: 1 },
    ]

    if (userStore.isPlatformManager) {
      list.push({ label: '待分发', value: result.distributeCount, unit: '台', type: 1 })
    }
    return list
  })

  watch(
    () => props.show,
    newVal => {
      if (!newVal) return
      if (searchParams.date.length == 0) {
        const firstDayOfMonth = proxy.$dayjs().startOf('month').format('YYYY-MM-DD')
        searchParams.date = [firstDayOfMonth, proxy.$dayjs().format('YYYY-MM-DD')]
      } else {
        ctx.$refs?.channelSelector?.handleQuery()
      }
      // 非管理员 要带上渠道
      if (!userStore.isPlatformManager) {
        searchParams.searchValue.businessId = userStore.userInfo.userId
      }
      handleSearch()
    }
  )

  const loading = ref(false)

  async function handleSearch() {
    if (searchParams.date?.length == 2) {
      searchParams.startDate = searchParams.date[0]
      searchParams.endDate = searchParams.date[1]
    }
    loading.value = true
    handleGetHireData()
    handleGetDeviceData()
  }

  async function handleGetHireData() {
    const { data, head: { ret = consts.RET_CODE.FAIL, msg = '获取列表失败' } = {} } = await apiGetHireStatistics(
      searchParams
    )
    loading.value = false
    if (ret !== consts.RET_CODE.SUCCESS) {
      notify.error(msg)
      return
    }
    Object.assign(result, data)
  }

  async function handleGetDeviceData() {
    const { data, head: { ret = consts.RET_CODE.FAIL, msg = '获取列表失败' } = {} } = await apiGetDeviceStatistics(
      searchParams
    )
    loading.value = false
    if (ret !== consts.RET_CODE.SUCCESS) {
      notify.error(msg)
      return
    }
    Object.assign(result, data)
  }

  function handleClose() {
    syncedShow.value = false
  }
</script>
<style lang="less" scoped>
  .data-item:not(:last-child) {
    border-right: #eeeeee solid 1px;
  }
</style>
