import store from 'store'
import consts from '@/consts'
import expirePlugin from 'store/plugins/expire'

// 扩展store，支持过期时间配置
store.addPlugin(expirePlugin)

/**
 * 获取缓存key
 * @param key 缓存key
 * @returns 缓存key
 */
export function getStorekey(key: string) {
  return `${consts.APP_NAME}-${key}`
}

export default store
