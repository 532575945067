import { generate } from '@arco-design/color'

export function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : ''
}

export default function (color: string) {
  const el = document.body as HTMLElement
  for (let i = 1; i <= 9; i++) {
    const hex = generate(color, { index: i })
    const rgb = hexToRgb(hex)
    el.style.setProperty('--primary-' + i, rgb)
  }
}
