import request from '@/utils/request'
import { AxiosRequestConfig } from 'axios'

/**
 * 获取 StorageSignature 上传options示例
 * @param {Object} storageenum 对象存储类型,可用值:ALIYUN,TXYUN,HUAWEI
 */
export const apiGetUploadOption = async (type: string) => {
  const params = {
    storageenum: type,
  }
  const { data } = await request.get<HttpResponse>('/v1/storage/getstoragesignature', { params })
  return data
}

/**
 * 获取区域列表
 * @param {Object} id 记录id
 */
export async function apiGetAreas(id?: string) {
  const { data } = await request.get<HttpResponse>('/v1/area/getareas', {
    params: {
      id,
    },
  })
  return data
}

/**
 * 订单统计数据
 * @param id
 * @returns
 */
export async function apiGetHireStatistics(params) {
  const { data } = await request.post<HttpResponse>('/v1/order/getstatistics', params)
  return data
}

/**
 * 订单统计数据
 * @param id
 * @returns
 */
export async function apiGetDeviceStatistics(params) {
  const { data } = await request.post<HttpResponse>('/v1/businessdevice/getstatistics', params)
  return data
}

/**
 * 运营统计数据
 * businessId
 * channelId
 * endTime
 * startTime
 * type	//查询类型 year年 month月 day日
 * @returns
 */
export async function apiGetDashboard(params) {
  const { data } = await request.post<HttpResponse>('/v1/order/getoperationalanalysis', params)
  return data
}

/**
 * 导出
 */
export async function apiExportDashboardData(params) {
  const url = '/v1/order/export'
  const config: AxiosRequestConfig = {
    method: 'post',
    url,
    responseType: 'blob',
  }
  const { data } = await request.post<Blob>(url, params, config)
  return data
}
