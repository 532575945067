import type { App } from 'vue'
import ArcoVueIcon from '@arco-design/web-vue/es/icon'
// arco 全局引用的库需要单独引入css
import '@arco-design/web-vue/es/message/style/css.js'
import '@arco-design/web-vue/es/modal/style/css.js'
import '@arco-design/web-vue/es/notification/style/css.js'

const install: (app: App) => void = app => {
  app.use(ArcoVueIcon)
}

export default install
