import { createApp } from 'vue'
import App from './App.vue'

import router from '@/router'
import pinia from '@/store/pinia'

import VueKeepScrollPlugin from '@/plugin/vue-keep-scroll'
// 挂载 dayjs
import dayjs from 'dayjs'

import LayoutSetup from './setup/layout-setup'
import ArcoSetup from './setup/arco-setup'
import VxeTableSetup from './setup/vxe-table-setup'

import './setup/styles'

const app = createApp(App)

app.use(VueKeepScrollPlugin)

app.use(LayoutSetup)
app.use(ArcoSetup)
app.use(VxeTableSetup)

app.use(pinia)
app.use(router)

app.config.globalProperties.$dayjs = dayjs

router.isReady().then(() => {
  app.mount('#app')
})
