<template>
  <div class="action-items-wrapper">
    <span v-if="state.actionItem.showRefresh" class="action-item" @click="onRefrehRoute">
      <refresh-icon />
    </span>
    <span v-if="state.actionItem.showFullScreen && state.device !== 'mobile'" class="action-item" @click="onScreenFull">
      <expand-icon />
    </span>

    <span class="action-item" @click="onShowDraw">
      <icon-bytedance-color />
    </span>
  </div>

  <statistics-draw v-model:show="showStatistics" />
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import { Message } from '@arco-design/web-vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useLayoutStore } from '../index'
  import { IconFullscreen as ExpandIcon, IconRefresh as RefreshIcon } from '@arco-design/web-vue/es/icon'
  import { useFullscreen } from '@vueuse/core'
  import StatisticsDraw from '@/components/business/statistics-draw/index.vue'

  import useEmit from '@/hooks/emit'
  export default defineComponent({
    name: 'RightActions',
    components: {
      ExpandIcon,
      RefreshIcon,
      StatisticsDraw,
    },
    setup() {
      let showStatistics = ref(false)
      const showSearchContent = ref(false)
      const searchContent = ref('')
      const settingRef = ref()
      const badgeValue = ref(3)
      const store = useLayoutStore()
      const router = useRouter()
      const route = useRoute()
      const emitter = useEmit()
      function onShowSearch() {
        emitter?.emit('show-search')
      }
      const { isSupported, enter } = useFullscreen(document.documentElement)
      function onScreenFull() {
        if (!isSupported) {
          Message.error('当前浏览器不支持全屏操作')
          return false
        }
        enter()
      }
      function onRefrehRoute() {
        window.location.reload()
        // router.replace({ path: '/redirect' + route.path })
      }
      function onShowSetting() {
        emitter?.emit('show-setting')
      }
      function onShowDraw() {
        showStatistics.value = true
      }
      return {
        showStatistics,
        settingRef,
        showSearchContent,
        searchContent,
        badgeValue,
        state: store.state,
        onShowSearch,
        onScreenFull,
        onRefrehRoute,
        onShowSetting,
        onShowDraw,
      }
    },
  })
</script>

<style lang="less" scoped>
  .action-items-wrapper {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
    .action-item {
      min-width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      height: 100%;
      &:hover {
        cursor: pointer;
        background-color: var(--color-secondary-hover);
      }
      :deep(.arco-badge-number, .arco-badge-dot, .arco-badge-text, .arco-badge-custom-dot) {
        transform: translate(10%, 20%);
      }
    }
  }
</style>
