/*
 * @Author: zhangzhenfei
 * @Date: 2022-03-10 10:35:19
 * @LastEditTime: 2022-03-10 10:45:20
 * @LastEditors: zhangzhenfei
 * @Description: app模块
 * @FilePath: /sadais-work/src/store/modules/app.ts
 */
import { RouteRecordRaw } from 'vue-router'
import { defineStore } from 'pinia'

import store from '@/store/pinia'

interface IAppState {
  routes: RouteRecordRaw[]
}

const useAppStore = defineStore('app', {
  state: (): IAppState => {
    return {
      routes: [],
    }
  },
  actions: {
    initRoutes(asyncRoutes: RouteRecordRaw[]) {
      this.routes = asyncRoutes
    },
  },
})

export default useAppStore

export function useAppStoreWithOut() {
  return useAppStore(store)
}
