/*
 * @Author: zhangzhenfei
 * @Date: 2022-02-18 10:45:20
 * @LastEditTime: 2022-02-18 11:25:08
 * @LastEditors: zhangzhenfei
 * @Description:
 * @FilePath: /sadais-work/src/hooks/useEmit.ts
 */
import { TinyEmitter } from 'tiny-emitter'
import { emitKey } from '../layouts/index'
import { inject } from 'vue'
export default function useEmit() {
  return inject<TinyEmitter>(emitKey)
}
