export interface ICONSTS {
  /** 支撑后台名称 */
  APP_NAME: string
  /** 访问标志Key */
  AGENT_KEY: string
  /** 访问标志值 */
  AGENT_VALUE: string
  TOKEN_KEY: string
  TOKEN_EXPIRED: number
  TOKEN_UNDEFINED: number
  API_LOGIN: string
  /** 返回RET状态码解析 */
  RET_CODE: {
    SUCCESS: number
    FAIL: number
    TOKEN_UNDEFINED: number
    TOKEN_EXPIRED: number
  }
  /** 第一页 */
  FIRST_PAGENO: number
  /** 最大页码 */
  MAX_PAGESIZE: number
  /**渠道标识 */
  BUSINESS: string
  /** 静态文件域名 */
  STATIC_BASE_URL: string

  MEASURE_DATA_STATUS?: any

  FORM_ITEM_TYPE?: any
}

const appName = import.meta.env.VITE_APP_TITLE as string
const appVersion = import.meta.env.VITE_APP_VERSION

const consts: ICONSTS = {
  APP_NAME: appName,
  BUSINESS: 'business',
  STATIC_BASE_URL: 'https://huangdanfiles.oss-cn-hangzhou.aliyuncs.com/',
  AGENT_KEY: 'sadais-agent',
  AGENT_VALUE: `${appName}/${appVersion}/WEB/DWHDY/${appName}//`,
  TOKEN_KEY: 'jwttoken',
  TOKEN_EXPIRED: 2,
  TOKEN_UNDEFINED: 3,
  API_LOGIN: '',
  RET_CODE: {
    SUCCESS: 0,
    FAIL: 1,
    TOKEN_UNDEFINED: 19,
    TOKEN_EXPIRED: 2,
  }, // 返回RET状态码解析
  FIRST_PAGENO: 1,
  MAX_PAGESIZE: 99999,
  MEASURE_DATA_STATUS: {
    0: { text: '正常', color: '#5AD8A6' },
    1: { text: '中高风险', color: '#FF9D4D' },
    2: { text: '高风险', color: '#E8684A' },
  },
  FORM_ITEM_TYPE: [
    { name: '输入框', type: 0 },
    { name: '下拉单选', type: 3 },
  ],
}

export default consts
