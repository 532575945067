<template>
  <a-sub-menu :key="menuInfo.key">
    <template #title>
      <span class="sub-menu-lable">{{ menuInfo.label }}</span>
    </template>
    <template #icon>
      <svg-icon v-if="menuInfo.icon" :name="menuInfo.icon" />
    </template>
    <template v-for="item in menuInfo.children" :key="item.key">
      <template v-if="item.children?.length">
        <sub-menu :menu-info="item" :key="item.key" />
      </template>
      <template v-else>
        <a-menu-item :key="item.key">
          <template #icon>
            <svg-icon v-if="item.icon" :name="item.icon" />
          </template>
          {{ item.label }}
        </a-menu-item>
      </template>
    </template>
  </a-sub-menu>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'SubMenu',
    props: {
      menuInfo: {
        type: Object,
        default: () => ({}),
      },
    },
  })
</script>
<style scoped>
  .sub-menu-lable {
    flex: 1;
    /* margin-left: 3px; */
  }
</style>
