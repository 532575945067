<!--
 * @Author: fuwenlong
 * @Date: 2022-03-24 19:08:59
 * @LastEditTime: 2023-08-08 11:27:41
 * @LastEditors: zhangzhenfei
 * @Description: 
 * @FilePath: \jaundice-web-console\src\components\business\channel-selector\index.vue
-->
<template>
  <a-select
    :style="{ width: syncedWidth }"
    placeholder="请选择渠道"
    allow-search
    :multiple="multiple"
    v-model="syncedValue"
    @clear="handleClear"
    :allow-create="false"
    :allow-clear="true"
    :show-extra-options="false"
    :filter-option="true"
  >
    <!-- <template #header>
        <div style="padding: 6px 12px;" >
          <a-checkbox :model-value="checkedAll" @change="handleChangeAll">全选</a-checkbox>
        </div>
      </template> -->
    <a-option v-for="item in result" :key="item.id" :value="`${item.id}`">
      {{ item.name }}
    </a-option>
  </a-select>
</template>

<script lang="ts" setup name="ChannelSelector">
  import { apiQueryList } from '@/api/modules/channel-info'
  import { IChannel } from '@/typings/modules/channel-info'
  import useUserStore from '@/store/modules/auth'
  const userStore = useUserStore()

  const emit = defineEmits(['update:value'])

  const props = defineProps({
    value: {
      type: [String, Number],
      default: '',
    },
    width: {
      type: String,
      default: '300px',
    },
    alwaysShow: {
      // 为true表示忽略权限控制 强行展示
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  })

  // 搜索参数
  let searchParams = {
    pageNo: 1,
    pageSize: 9999,
    searchValue: {
      name: '', //渠道名称关键字
    },
  }
  let checkedId = ref('') // 选中id
  let result = ref<IChannel[]>([]) // 选项
  const checkedAll = ref(false) // 全选
  // 同步到value
  // const syncedValue = useVModel(props, 'value', emit, { passive: true })

  const syncedWidth = computed(() => {
    const width = props.width
    if (!width.endsWith('px') && !width.endsWith('%')) return `${width}px`
    return width
  })

  watch(
    () => checkedId.value,
    () => {
      syncedValue.value = checkedId.value
    }
  )

  const showResult = computed(() => {
    if (props.alwaysShow) return true
    return result.value.length > 0
  })

  const syncedValue = computed({
    get() {
      if (props.multiple) return props.value == 0 ? [] : `${props.value}`.split(',')
      return props.value == 0 ? '' : `${props.value}`
    },
    set(value: any) {
      if (props.multiple) return emit('update:value', value ? value.join(',') : '')
      return emit('update:value', value)
    },
  })

  onBeforeMount(() => {
    handleQuery()
  })

  const handleQuery = async () => {
    const { data, head: { ret = consts.RET_CODE.FAIL, msg = '获取列表失败' } = {} } = await apiQueryList(searchParams)
    if (ret !== consts.RET_CODE.SUCCESS) {
      notify.error(msg)
      return
    }
    const businessIds = (userStore?.bussinessId ? userStore?.bussinessId?.split(',') : []) || []
    if (businessIds.length > 0) {
      result.value = data.result.filter(item => {
        return businessIds.includes(item?.id + '')
      })
    } else {
      result.value = data.result
    }
  }

  async function handleSearch(value: string) {
    // searchParams.searchValue.name = value
    // handleQuery()
  }
  async function handleClear() {
    handleSearch('')
  }

  defineExpose({ handleQuery })
</script>
<style lang="less" scoped></style>
