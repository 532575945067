<template>
  <router-view v-slot="{ Component, route }">
    <!-- <transition :name="state.pageAnim + '-transform'" mode="out-in" appear :duration="100"> -->
    <keep-alive :include="cachedViews">
      <component :is="Component" :key="route.fullPath" />
    </keep-alive>
    <!-- </transition> -->
  </router-view>
</template>

<script lang="ts">
  import { computed, defineComponent } from 'vue'
  import store from '../store'
  export default defineComponent({
    name: 'LayoutMain',
    setup() {
      const state = store.state
      const cachedViews = computed(() => {
        return state.cachedView.map(it => it)
      })
      return {
        state,
        cachedViews,
      }
    },
  })
</script>
