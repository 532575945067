<!--
 * @Author: fuwenlong
 * @Date: 2022-03-24 19:08:59
 * @LastEditTime: 2022-04-14 14:44:46
 * @LastEditors: zhangzhenfei
 * @Description: 
 * @FilePath: \jaundice-web-console\src\components\business\change-password-modal\index.vue
-->
<template>
  <a-modal v-model:visible="syncedShow" @before-ok="handleOk" unmountOnClose @cancel="handleClose">
    <template #title> 修改密码 </template>
    <a-form ref="formRef" class="edit-form" :model="form" :rules="formRules">
      <a-form-item v-if="formRules.oldpwd" field="oldpwd" label="旧密码">
        <a-input-password v-model="form.oldpwd" placeholder="请输入新密码" allow-clear />
      </a-form-item>
      <a-form-item field="newpwd" label="新密码">
        <a-input-password v-model="form.newpwd" placeholder="请输入新密码" allow-clear />
      </a-form-item>
      <a-form-item field="confirmPwd" label="确认密码">
        <a-input-password v-model="form.confirmPwd" placeholder="请再次输入新密码" allow-clear />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script lang="ts" setup name="ChangePasswordModal">
  import { useVModel } from '@vueuse/core'
  import { FormInstance } from '@arco-design/web-vue/es/form'
  import { apiChangePwd } from '@/api/modules/user'
  import useUserStore from '@/store/modules/auth'

  type IPassword = { oldpwd?: string; newpwd?: string; confirmPwd?: string }

  const emit = defineEmits(['update:show'])

  const props = defineProps({
    account: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: true,
    },
  })

  const form = reactive<IPassword>({ newpwd: '' })

  // 同步到value
  const syncedShow = useVModel(props, 'show', emit, { passive: true })
  const formRules = computed(() => {
    const rules: Record<string, any> = {
      newpwd: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
      confirmPwd: [
        { required: true, message: '请输入再次新密码', trigger: 'blur' },
        {
          validator: (value: any, callback: any) => {
            if (value === form?.newpwd) {
              callback()
            } else {
              callback('两次输入的密码不一致')
            }
          },
        },
      ],
    }
    // 当修改指定账号account时不用输入旧密码
    if (!props.account) {
      rules.oldpwd = [{ required: true, message: '请输入旧密码', trigger: 'blur' }]
    }

    return rules
  })
  const userStore = useUserStore()
  const formRef = ref<FormInstance>()

  // 初始化
  onMounted(() => {})

  function handleOk() {
    formRef.value?.validate(async errors => {
      if (errors) return
      const params: any = { ...form }
      if (!props.account) {
        params.id = userStore.userInfo.id
      } else {
        params.account = props.account
      }
      const {
        head: { ret = consts.RET_CODE.FAIL, msg = '修改失败' },
      } = await apiChangePwd(params)

      if (ret !== consts.RET_CODE.SUCCESS) {
        return notify.error({
          title: '错误',
          content: msg,
        })
      }
      notify.success({
        title: '温馨提示',
        content: '密码修改成功',
      })

      return handleClose()
    })
    return false
  }
  function handleClose() {
    syncedShow.value = false
  }
</script>
<style lang="less" scoped></style>
