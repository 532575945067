import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import consts from '@/consts'
import { getToken, getBusinessInfo } from '@/utils/auth'
import { logout } from './auth'

const baseURL = `${import.meta.env.VITE_BASE_API}${import.meta.env.VITE_API_URL_PREFIX}`

const service = axios.create({
  baseURL,
  withCredentials: false, // 开启跨域身份凭证
  method: 'post',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    [consts.AGENT_KEY]: consts.AGENT_VALUE,
  },
  timeout: 60000,
})

// Request interceptors
service.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const token = getToken()
    const businessInfo = getBusinessInfo()
    if (!config.headers) {
      config.headers = {}
    }
    config.headers[consts.BUSINESS] = businessInfo || ''
    config.headers[consts.TOKEN_KEY] = token || ''
    config.headers[consts.AGENT_KEY] = consts.AGENT_VALUE
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// Response interceptors
service.interceptors.response.use(
  async (response: AxiosResponse<HttpResponse>) => {
    const res = response.data
    const { head: { ret = -1 } = {} } = res
    if (ret === consts.TOKEN_EXPIRED || ret === consts.TOKEN_UNDEFINED) {
      logout(true)
    }
    return response
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
