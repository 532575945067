import { IChannel } from '@/typings/modules/channel-info'
import request from '@/utils/request'

/**
 * 分页获取列表记录
 * @param params 参数
 * @returns 列表记录
 */
export async function apiQueryList(params: ListSearchCondition) {
  const url = '/v1/business/findpagelist'
  const { data } = await request.post<HttpResponse<ListResult<IChannel>>>(url, params)
  return data
}

/**
 * 创建/更新
 * @param {Object} business 对象信息
 */
export async function apiSaveOrUpdate(business: IChannel) {
  const { data } = await request.post<HttpResponse>('/v1/business/saveorupdate', {
    ...business,
  })
  return data
}

/**
 * 删除
 * @param {Object} ids id信息
 */
export async function apiDelete(ids: string[]) {
  const { data } = await request.post<HttpResponse>('/v1/business/del', {
    ids,
  })
  return data
}

/**
 * 获取信息
 * @param {Object} id 记录id
 */
export async function apiGetById(id: string) {
  const { data } = await request.get<HttpResponse<IChannel>>('/v1/business/getbyid', {
    params: {
      id,
    },
  })
  return data
}

/**
 * 刷新二维码
 * @param {Number} id 记录id
 */
export async function apiRefreshqrcode(businessId: number) {
  const { data } = await request.get<HttpResponse>('/v1/business/flushqrcode', {
    params: {
      businessId,
    },
  })
  return data
}
