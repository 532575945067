<template>
  <a-config-provider :locale="zhCN" size="medium">
    <router-view />
  </a-config-provider>
</template>

<script>
  import zhCN from '@arco-design/web-vue/es/locale/lang/zh-cn'

  export default {
    data() {
      return {
        zhCN,
      }
    },
  }
</script>
