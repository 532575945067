import { IResource } from '@/typings/modules/resource'
import { IRole } from '@/typings/modules/role'
import { IUser } from '@/typings/modules/user'
import storage, { getStorekey } from '@/utils/storage'

const TOKEN = getStorekey('Token')
const ROLES = getStorekey('Roles')
const USER_INFO = getStorekey('UserInfo')
const RESOURCES = getStorekey('Resource')
const BUSINESS_INFO = getStorekey('BusinessInfo')

export function getBusinessInfo() {
  const token = storage.get(BUSINESS_INFO)
  return token || ''
}

export function setBusinessInfo(businessInfo: string) {
  const result = storage.set(BUSINESS_INFO, businessInfo)
  return result
}

export function removeBusinessInfo() {
  const result = storage.remove(BUSINESS_INFO)
  return result
}

export function getToken() {
  const token = storage.get(TOKEN)
  return token || ''
}

export function setToken(token: string, expireDate: number) {
  const result = storage.set(TOKEN, token, expireDate)
  return result
}

export function removeToken() {
  const result = storage.remove(TOKEN)
  return result
}

export function getRoles(): IRole[] {
  const roles = storage.get(ROLES)
  return roles || []
}

export function setRoles(roles: IRole[]) {
  const result = storage.set(ROLES, roles)
  return result
}

export function removeRoles() {
  const result = storage.remove(ROLES)
  return result
}

export function getUserInfo(): IUser {
  const userInfo = storage.get(USER_INFO)
  return userInfo
}

export function setUserInfo(userInfo: IUser) {
  const result = storage.set(USER_INFO, userInfo)
  return result
}

export function removeUserInfo() {
  const result = storage.remove(USER_INFO)
  return result
}

export function getResources(): IResource[] {
  const resources = storage.get(RESOURCES)
  return resources
}

export function setResources(resource: IResource) {
  const result = storage.set(RESOURCES, resource)
  return result
}

export function removeResources() {
  const result = storage.remove(RESOURCES)
  return result
}

/**
 * 用户注销登录
 * @param stopTask 是否停止执行js
 */
export function logout(stopTask = false) {
  console.log('用户注销登录')
  removeRoles()
  removeToken()
  removeUserInfo()
  removeResources()
  removeBusinessInfo()
  // 页面重定向登录页
  // const pathname = window.location.pathname
  // if (pathname !== '/login' && pathname !== '/') {
  //   location.href = '/login?redirect=' + encodeURIComponent(location.href)
  // } else {
  //   location.href = '/login'
  // }

  // 重新进入系统的redirect可能会无权限，因此统一去掉
  location.href = '/login'

  if (stopTask) {
    throw new Error('user logout')
  }
}
